import React from 'react';
import ServiceComponent from './ServiceComponent';
import {Helmet} from 'react-helmet'
export default function BTLComponent() {

    const description = `Diseñamos y ejecutamos experiencias creativas, memorables y de alto impacto a través de la definición de estrategias que logren llegar de forma asombrosa a
    los diferentes targets de comunicación.`

  return (
    <>
    <Helmet>
      <title>BTL | Blue Group Marketing | Bolivia</title>
      <meta
        name="description"
        content="Diseñamos y ejecutamos experiencias creativas, memorables y de alto impacto a través de la definición de estrategias que logren llegar de forma asombrosa a los diferentes targets de comunicación."
      />
      <meta name="keywords" content="branding, marca, naming, identidad" />
      <meta name="robots" content="index, follow"/>
      <meta name="googlebot" content="index, follow"/>
      <meta property="og:url" content={`${process.env.FRONTENDURL}BTLservices`} />
      <link rel="canonical" href={process.env.FRONTENDURL} />
      <meta property="og:title" content="BTL | Blue Group Marketing | Bolivia" />
      <meta property="og:description" content="Diseñamos y ejecutamos experiencias creativas, memorables y de alto impacto a través de la definición de estrategias que logren llegar de forma asombrosa a los diferentes targets de comunicación." />
    </Helmet>
    <ServiceComponent description={description} service="BTL" type="BTL" fontsize="9">
      <div className="max-w-7xl mx-auto">
        <ul className="text-3xl italic font-bold mt-20 text-center sm:text-left">
          <li className="text-bluegroup-100 mb-10"><img className="sm:inline w-20 mx-auto sm:mx-5" src={require('../../assests/OurServices/BTL/endomarketing_icon.png')}/>Endomarketing</li>
          <li className="text-bluegroup-800 mb-10"><img className="sm:inline w-20 mx-auto sm:mx-5" src={require('../../assests/OurServices/BTL/activaciones_icon.png')}/>Activaciones</li>
          <li className="text-bluegroup-300 mb-10"><img className="sm:inline w-20 mx-auto sm:mx-5" src={require('../../assests/OurServices/BTL/eventos_icon.png')}/>Eventos</li>
        </ul>
      </div>
    </ServiceComponent>
    </>
  );
}
