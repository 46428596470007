import React from 'react';
import ServiceComponent from './ServiceComponent';
import estrategias_icon from '../../assests/OurServices/Trade/estrategias_icon.png'
import personal_icon from '../../assests/OurServices/Trade/personal_icon.png'
import auditoria_icon from '../../assests/OurServices/Trade/auditoria_icon.png'
import merchandising_icon from '../../assests/OurServices/Trade/merchandising_icon.png'
import promocion_icon from '../../assests/OurServices/Trade/promocion_icon.png'
import {Helmet} from 'react-helmet'
export default function TradeComponent() {

    const description = <>
      <p className="mb-14">Somos especialistas en desarrollar estrategias dentro de los canales de distribución, que logren fidelizar y mejorar la experiencia de compra de los clientes.</p>
      <p>Entendemos que debemos definir estrategias que logren un beneficio mutuo con los clientes empresariales.</p>
    </>

    const sections = [
      {
        icon:estrategias_icon,
        color:"bg-bluegroup-100",
        subtitle:"Definición e implementación  de estrategias para los  canales de distribución",
        content:"Construimos la estrategia según los objetivos de negocio. La definimos y la implementamos."
      },
      {
        icon:personal_icon,
        color:"bg-bluegroup-800",
        subtitle:"Dotación de personal eventual capacitado en atención al cliente",
        content:"Trabajamos con promotores capacitados para impulsar el producto, buscando transmitir los beneficios dentro del punto de venta."
      },
      {
        icon:auditoria_icon,
        color:"bg-bluegroup-200",
        subtitle:"Auditorías de control de imagen, stock y personal en puntos de venta",
        content:"Realizamos auditorías dentro de los puntos de venta, recabamos información sobre el manejo de la imagen del producto, el stock y el personal. Esto con el fin de mejorar la participación del producto dentro del punto de venta y encontrar oportunidades."
      },
      {
        icon:merchandising_icon,
        color:"bg-bluegroup-500",
        subtitle:"Merchandising estratégico",
        content:"Trabajamos en el diseño y producción de merchandising, pensado estratégicamente para que el producto sea el protagonista dentro del punto de venta."
      },
      {
        icon:promocion_icon,
        color:"bg-bluegroup-600",
        subtitle:"Promociones",
        content:"Creamos la idea y la ejecutamos, cuidamos del mínimo detalle para que la promoción sea totalmente efectiva."
      },
    ]

  return (
    <>
    <Helmet>
      <title>Trade Marketing | Blue Group Marketing | Bolivia</title>
      <meta
        name="description"
        content="Somos especialistas en desarrollar estrategias dentro de los canales de distribución, que logren fidelizar y mejorar la experiencia de compra de los clientes."
      />
      <meta name="keywords" content="Promociones, Merchandising, imagen, stock" />
      <meta name="robots" content="index, follow"/>
      <meta name="googlebot" content="index, follow"/>
      <meta property="og:url" content={`${process.env.FRONTENDURL}TradeServices`} />
      <link rel="canonical" href={process.env.FRONTENDURL} />
      <meta property="og:title" content="Trade Marketing  | Blue Group Marketing | Bolivia" />
      <meta property="og:description" content="Somos especialistas en desarrollar estrategias dentro de los canales de distribución, que logren fidelizar y mejorar la experiencia de compra de los clientes." />
    </Helmet>
    
    <ServiceComponent description={description} service="TRADE MARKETING" type="Trade">
      <ul className="text-2xl md:text-3xl mt-20 text-center md:text-left">
        {sections.map((section,index)=>
          <li key={index} className="mb-20">
            <div className={`mx-10 md:mx-0 md:-ml-24 p-5 ${section.color} rounded-2xl sm:rounded-full flex flex-wrap md:flex-nowrap justify-center md:justify-start max-w-3xl     lg:max-w-4xl xl:max-w-6xl mb-5`}>
              <div className="w-80 md:w-40 ml-0 md:ml-20 lg:ml-28 xl:ml-64 md:mr-10">
                  <img className="mx-auto" src={section.icon}/>
              </div>
              <h2 className="trade-subtitle text-white italic font-bold my-auto">
                {section.subtitle}</h2>
            </div>
            <div className="max-w-7xl mx-auto px-10 individual-content">
              <p className="text-bluegroup-100">{section.content}</p>
            </div>
          </li>
        )}
      </ul>
    </ServiceComponent>
    </>
  );
}