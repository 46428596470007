import React, { useReducer, useState } from 'react';
import { goTo } from '../util/utils';
import { gql, useMutation } from '@apollo/client';
import Modal from '../util/Modal';

const ADD_CUSTOMER = gql`
mutation newCustomer($name: String!, $message: String!, $company:String!, $phone:String!, $email:String!, $businessId: String! ){
  newCustomer(input:{name: $name, message:$message, company: $company, phone:$phone, email:$email, businessId: $businessId}){
    _id
  }
}
`;

export default function ContactComponent() {  

  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState('Mensaje enviado correctamente')
  const [message, setMessage] = useState('¡Muchas gracias por tu consulta! Nos comunicaremos contigo lo mas pronto posible.')

  const [newCustomer, { error }] = useMutation(ADD_CUSTOMER);
  const [data, setData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      nombre:'',
      correo: '',
      telefono: '',
      mensaje: '',
      empresa:''
    }
  );

    
  const links = {
    linkedIn:'https://www.linkedin.com/company/blue-group-marketing',
    facebook:'https://www.facebook.com/bluegroupbolivia/',
    whatsapp:'https://wa.me/59179960605',
    instagram: 'https://www.instagram.com/p/CSZavEEAkkm/?utm_medium=copy_link'

  }


  async function send(event){
    event.preventDefault();
    try {
      await newCustomer({
        variables: {
          name: data.nombre,
          message: data.mensaje,
          company: data.empresa,
          phone: data.telefono.toString(),
          email: data.correo,
          businessId: process.env.BUSINESSID,
        }});
      setTitle('Mensaje enviado correctamente');
      setMessage('¡Muchas gracias por tu consulta! Nos comunicaremos contigo lo más pronto posible.');
    } catch (error) {
      console.log(error,"error");
      setTitle("Mensaje no enviado");
      setMessage("Por favor, intente más tarde.");
    }
    setOpen(true);
  }

  function changeHandler(e){
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    setData({[name]:value});
  }

  return (
    <div id="contact">
      <div className="grid lg:grid-cols-2 shadow-lg relative">
      <img className="absolute -top-36 left-10 hidden xl:block" src={require('../../assests/Contact/figure_3.png')}/>
        <div className="w-full h-full hidden lg:block">
           <img className="w-full h-full" src={require('../../assests/Contact/contact_image.png')}/>
        </div>

      <div className="w-auto h-auto bg-white rounded-br-lg relative">
          <img className="w-56 absolute lg:-bottom-40 lg:-left-20 hidden xl:block" src={require('../../assests/Contact/figure_4.png')}/>
          <div className="mt-auto">
          <div className="text-center pt-10 lg:pt-20 text-bluegroup-100">
            <h2 className="text-4xl md:text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl font-bold">Escríbenos</h2>
            <h3 className="text-1xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl">y nos comunicaremos contigo.</h3>
          </div>
          <form className="mt-10 sm:mt-20 max-w-2xl mx-auto px-10" onSubmit={event=>send(event)}>
            <div className="flex flex-wrap justify-center grid grid-cols-1 sm:grid-cols-2 grid">
                <input className="border outline-none py-2 px-3 rounded-full sm:mr-5 mb-5" 
                type="text" 
                name="nombre"
                required
                value={data.nombre} 
                onChange={e=>(changeHandler(e))} 
                placeholder="Nombre"/>
                <input className="border outline-none py-2 px-3 rounded-full mb-5" 
                type="text" 
                name="empresa"
                required
                value={data.empresa} 
                onChange={e=>(changeHandler(e))} 
                placeholder="Empresa"/>
              <input className="border outline-none py-2 px-3 rounded-full sm:mr-5 mb-5" 
                type="email" 
                name="correo"
                value={data.correo} 
                onChange={e=>(changeHandler(e))}
                placeholder="Correo"/>
              <input className="border outline-none py-2 px-3 rounded-full mb-5" 
                type="number" 
                name="telefono" 
                value={data.telefono} 
                onChange={e=>(changeHandler(e))} 
                required
                placeholder="Teléfono"/>
            </div>
            <textarea className="outline-none h-32 block border py-2 px-3 rounded-lg w-full" 
            name="mensaje" 
            value={data.mensaje} 
            onChange={e=>(changeHandler(e))}
            required
            placeholder="Escribe tu mensaje..."/>
            <div className="w-32 mx-auto lg:mr-0 lg:ml-auto">
              <button className="outline-none focus:outline-none my-5 text-white bg-bluegroup-800 hover:bg-bluegroup-900 px-5 py-2 rounded-full text-3xl italic" type="submit">Enviar</button>
            </div>
          </form>
          </div>
          <Modal open={open} setOpen={setOpen} message={message} title={title}/>
      </div>
   
      </div>
    
    <div className="contact overflow-hidden">
    <div className=" max-w-7xl mx-auto">
        <div className="pt-10 lg:pt-20 px-10 lg:mt-10">
          <h3 className="text-white text-4xl md:text-5xl text-center font-bold lg:text-8xl lg:text-left">Encuéntranos</h3>
          <h2 className="text-white text-3xl md:text-4xl text-center font-medium lg:text-6xl lg:text-left">y contáctanos  </h2>
        </div>
        <div className="flex flex-wrap mt-14 justify-center">
          <div className="mx-10 z-10">
              <iframe className="map-view" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3807.8026632003925!2d-66.18456268310547!3d-17.373226165771484!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x93e37463cd8c7f6f%3A0xd2928c028731e4ef!2sChipaya%2C%20Cochabamba!5e0!3m2!1sen!2sbo!4v1623023757098!5m2!1sen!2sbo" width="600" height="450" style={{border:"0"}} allowFullScreen="" loading="lazy"></iframe>
          </div>
          <div className="text-white text-sm md:text-xl lg:text-3xl h-full mb-10">
              <p className="my-5"><img className="inline mx-5 w-5" src={require('../../assests/Contact/maps_icon.png')}/> Calle Chipaya, N° 1760</p>
              <p className="my-5"><img className="inline mx-5 w-5" src={require('../../assests/Contact/mail_icon.png')}/> monica.sossa@bluegroupmarketing.com</p>
              <p className="my-5"><img className="inline mx-5 w-4" src={require('../../assests/Contact/phone_icon.png')}/> +591 79960605</p>
          </div>
        </div>
        <footer className="footer transform -mt-10">
          <div className="text-white hidden lg:block">
            <h2 className="footer-message ml-auto md:text-3xl lg:text-4xl mb-2 font-medium">Síguenos en nuestras:</h2>
            <h1 className="footer-message ml-auto lg:text-5xl font-bold">REDES SOCIALES</h1>
            <div className="w-96 ml-auto md:text-right mr-20 my-10">
              <a href={links.linkedIn} target="_blank" rel="noopener noreferrer">
                <img className="inline mx-5 w-14" title="LinkedIn" src={require('../../assests/Contact/linkedIn_icon.png')}/>
              </a>
              <a href={links.whatsapp} target="_blank" rel="noopener noreferrer">
                <img className="inline mx-5 w-14" title="Whatsapp" src={require('../../assests/Contact/whatsapp_icon.png')}/>
              </a>
              <a href={links.facebook} target="_blank" rel="noopener noreferrer">
                <img className="inline mx-5 w-7 mt-2" title="Facebook" src={require('../../assests/Contact/facebook_icon.png')}/>
              </a>
              <a href={links.instagram} target="_blank" rel="noopener noreferrer">
                <img className="inline mx-5 w-14 mt-2" title="Facebook" src={require('../../assests/Contact/instagram_icon.png')}/>
              </a>
            </div>
          </div>
          <div className="flex flex-wrap justify-between px-5 bg-bluegroup-100">
            <div className="text-white my-5 text-xs lg:text-lg pt-2 order-last md:order-none w-full text-center md:w-auto">©  2021 Todos los derechos reservados</div>
            <div className="text-white my-5 block lg:hidden order-first md:order-none w-full text-center md:w-auto">
              <a href={links.linkedIn} target="_blank" rel="noopener noreferrer">
                <img className="inline mx-5 w-5" src={require('../../assests/Contact/linkedIn_icon.png')}/>
              </a>
              <a href={links.whatsapp} target="_blank" rel="noopener noreferrer">
                <img className="inline mx-5 w-5" src={require('../../assests/Contact/whatsapp_icon.png')}/>
              </a>
              <a href={links.facebook} target="_blank" rel="noopener noreferrer">
                <img className="inline mx-5 w-3" src={require('../../assests/Contact/facebook_icon.png')}/>
              </a>
              <a href={links.instagram} target="_blank" rel="noopener noreferrer">
                <img className="inline mx-5 w-5" src={require('../../assests/Contact/instagram_icon.png')}/>
              </a>
            </div>
            <div className="flex justify-center text-white text-center text-md lg:text-2xl md: my-5 w-full md:justify-end md:w-auto px-5">
              <button className="bg-bluegroup-700 font-bold py-2 px-2 md:px-4 rounded-full inline mx-1 lg:mx-3 focus:outline-none focus:font-bold hover:bg-bluegroup-800" onClick={()=>goTo("aboutUs")}>Nosotros</button>
              <button className="bg-bluegroup-700 font-bold py-2 px-2 md:px-4 rounded-full inline mx-1 lg:mx-3 focus:outline-none focus:font-bold hover:bg-bluegroup-800" onClick={()=>goTo("ourServices")}>Servicios</button>
              <button className="bg-bluegroup-700 font-bold py-2 px-2 md:px-4 rounded-full inline mx-1 lg:mx-3 focus:outline-none focus:font-bold hover:bg-bluegroup-800" onClick={()=>goTo("contact")}>Contacto</button>
            </div>
          </div>
        </footer>
      </div>
    </div>
    </div>
  );
}