import React from 'react';
import '../../styles/HomeStyles.css'

export default function AboutComponent() {
  return (
    <div id="aboutUs" className="about relative">
      <img className="figure-2 absolute pl-24 -top-48 lg:left-20 xl:left-96 w-60 hidden lg:block" src={require("../../assests/home/figure_2.png")}/>
      <div className="max-w-7xl mx-auto">
      <div className="pt-20 px-10 lg:pb-20">
        <h3 className="text-white text-4xl text-center md:text-5xl md:text-left font-medium">Acerca de</h3>
        <h2 className="text-white text-5xl text-center md:text-8xl md:text-left font-bold">NOSOTROS</h2>
      </div>
      <div className="flex flex-wrap justify-center xl:justify-end">
        <div className="mision bg-opacity-90 mx-10 w-80 bg-white py-8 px-8  rounded-full mt-10">
          <img className="mision_icon mx-auto mb-5" src={require("../../assests/About/mision_icon.png")}/>
          <div className="text-3xl font-semibold text-center">MISIÓN</div>
          <div className="line bg-bluegroup-800"></div>
          <p className="text-center mt-2 text-gray-600">Ofrecer soluciones de mercadeo de forma personalizada, que permiten fortalecer las marcas de nuestros clientes a través de nuestro profesionalismo, creatividad y pasión por el marketing.</p>
        </div>
        <div className="vision bg-opacity-90 mx-10 w-80 bg-white py-8 px-8 rounded-full mt-10">
            <img className="vision_icon mx-auto mb-5" src={require("../../assests/About/vision_icon.png")}/>
            <div className="text-3xl font-semibold text-center">VISIÓN</div>
            <div className="line bg-bluegroup-200"></div>
            <p className="text-center mt-2 text-gray-600">Convertirnos en parte del equipo de trabajo de nuestros clientes. Cumpliendo con sus objetivos que permitan alcanzar el éxito.</p>
        </div>
        <div className="vision bg-opacity-90 mx-10 w-80 bg-white py-8 px-8 rounded-full mt-10">
            <img className="vision_icon mx-auto mb-5" src={require("../../assests/About/proposito_icon.png")}/>
            <div className="text-3xl font-semibold text-center">PROPÓSITO</div>
            <div className="line bg-bluegroup-200"></div>
            <p className="text-center mt-2 text-gray-600">Llevar a nuestros clientes hacia el éxito, trabajando de forma conjunta, creando una relación de confianza absoluta.</p>
        </div>
      </div>
      </div>

      </div>
  );
}