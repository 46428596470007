import React from 'react';
import ServiceComponent from './ServiceComponent';
import {Helmet} from 'react-helmet'

export default function ProduccionComponent() {

    const description = `Producimos todo tipo de mobiliario publicitario personalizado con los mejores diseños y acabados.`

  return (
    <>
    <Helmet>
      <title>Producción | Blue Group Marketing | Bolivia</title>
      <meta
        name="description"
        content="Producimos todo tipo de mobiliario publicitario personalizado con los mejores diseños y acabados."
      />
      <meta name="keywords" content="Producción, Letreros publicitarios, Stands pequeños y grandes, Góndolas" />
      <meta name="robots" content="index, follow"/>
      <meta name="googlebot" content="index, follow"/>
      <meta property="og:url" content={`${process.env.FRONTENDURL}ProductionServices`} />
      <link rel="canonical" href={process.env.FRONTENDURL} />
      <meta property="og:title" content="Producción | Blue Group Marketing | Bolivia" />
      <meta property="og:description" content="Producimos todo tipo de mobiliario publicitario personalizado con los mejores diseños y acabados." />
    </Helmet>
    <ServiceComponent description={description} service="PRODUCCIÓN" type="Produccion">
      <div className="mx-auto" style={{maxWidth:"1450px"}}>
      <ul className="text-3xl italic font-bold mt-20 text-center sm:text-left">
        <li className="text-bluegroup-100 mb-10">
          <img className="sm:inline w-32 mx-auto sm:mx-5" src={require('../../assests/OurServices/Produccion/cabecera_icon.png')}/>Cabeceras de supermercados
        </li>
        <li className="text-bluegroup-300 mb-10">
          <img className="sm:inline w-32 mx-auto sm:mx-5" src={require('../../assests/OurServices/Produccion/letrero_icon.png')}/>Letreros publicitarios
        </li>
        <li className="text-bluegroup-200 mb-10">
          <img className="sm:inline w-32 mx-auto sm:mx-5" src={require('../../assests/OurServices/Produccion/stand_icon.png')}/>Stands pequeños y grandes
        </li>
        <li className="text-bluegroup-800 mb-10">
          <img className="sm:inline w-32 mx-auto sm:mx-5" src={require('../../assests/OurServices/Produccion/gondola_icon.png')}/>Góndolas
        </li>
      </ul>
      </div>
    </ServiceComponent>
    </>
  );
}