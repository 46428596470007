import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/HomeStyles.css'

export default function ServicesComponent() {
  return (
    <div id="ourServices" className="services relative">
       <section className="services-title flex justify-center md:justify-start">
         <div className="title text-bluegroup-300 text-center md:text-left xl:px-56">
          <div className="font-semibold text-3xl md:text-4xl lg:text-5xl">Conoce nuestros:</div>
          <div className="text-4xl md:text-6xl lg:text-8xl font-bold">SERVICIOS</div>
         </div>
       </section>
        <div className="max-w-7xl mx-auto">
        <div className="services-icons flex flex-wrap justify-center lg:justify-center">
            <div className="mx-10 w-80 bg-bluegroup-200 py-8 px-8 shadow-lg  rounded-xl mt-10 lg:mb-32">
              <Link to="/BTLservices">
              <div className="text-3xl text-white font-semibold text-center">BTL</div>
              <div className="line aspect-w-5 bg-bluegroup-800"></div>
              <img className="service_icon mx-auto my-5" src={require('../../assests/OurServices/BTL_icon.png')}/>
              </Link>
            </div>
          
          
            <div className="mx-10 w-80 bg-bluegroup-200 py-8 px-8 shadow-lg align-middle rounded-xl mt-10 lg:mb-32">
              <Link to="/BrandingServices">
              <div className="text-3xl text-white font-semibold text-center">BRANDING</div>
              <div className="line aspect-w-5 bg-bluegroup-800"></div>
              <img className="service_icon mx-auto my-5" src={require('../../assests/OurServices/Branding_icon.png')}/>
              </Link>
            </div>
      
          
          <div className="mx-10 w-80 bg-bluegroup-200 py-4 px-8 shadow-lg rounded-xl mt-10 lg:mb-32">
            <Link to="/MarketingServices">
            <div className="text-3xl text-white font-semibold text-center">MARKETING DIGITAL</div>
            <div className="line aspect-w-5 bg-bluegroup-800"></div>
            <img className="service_icon mx-auto my-5" src={require('../../assests/OurServices/Marketing_icon.png')}/>
            </Link>
          </div>
          <div className="mx-10 w-80 bg-bluegroup-100 py-4 px-8 shadow-lg rounded-xl mt-10 lg:mb-32">
            <Link to="/TradeServices">
            <div className="text-3xl text-white font-semibold text-center">TRADE MARKETING</div>
            <div className="line aspect-w-5 bg-bluegroup-800"></div>
            <img className="service_icon mx-auto my-5" src={require('../../assests/OurServices/Trade_icon.png')}/>
            </Link>
          </div>
          <div className="mx-10 w-80 bg-bluegroup-100 py-8 px-8 shadow-lg rounded-xl mt-10 lg:mb-32">
            <Link to="/ProductionServices">
            <div className="text-3xl text-white font-semibold text-center">PRODUCCIÓN</div>
            <div className="line aspect-w-5 bg-bluegroup-800"></div>
            <img className="service_icon mx-auto my-5" src={require('../../assests/OurServices/Produccion_icon.png')}/>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}