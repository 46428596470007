import React from 'react';
import { withRouter } from 'react-router-dom';
import '../../styles/PagesStyles.css'

export default withRouter( function ServiceComponent(props) {


    const description = props.description
    const content = props.children
    const service = props.service
    const type = props.type

    var divStyle = {
      backgroundImage: 'url(' + require(`../../assests/OurServices/${type}/fondo.png`) + ')',
      backgroundRepeat:'no-repeat',
    };

        
  const links = {
    linkedIn:'https://www.linkedin.com/company/blue-group-marketing',
    facebook:'https://www.facebook.com/bluegroupbolivia/'
  }



  function goBack(){
      if(props.url){
          props.history.push(`/${props.url}`)
      }else{
       props.history.goBack();
      }
  }

  function go(id){
    props.history.push(`/?section=${id}`)
  }

  return (
     <div>
        <div className="h-auto relative" style={divStyle}>
            <img className="service-page-title w-full pb-80 lg:pb-96 hidden md:block" src={require('../../assests/OurServices/service_title_image.png')}/>
            <img className="service-page-title w-full pb-80 lg:pb-96 block md:hidden" src={require('../../assests/OurServices/service_title_image-mobile.png')}/>
            <div className="h-48 xl:h-96"></div>
            <img className="absolute -bottom-24 2xl:-bottom-40 left-10 hidden lg:block w-20 xl:w-48 2xl:w-52" src={require('../../assests/OurServices/figure_5.png')}/>
          <div className="max-w-7xl mx-auto flex justify-center md:justify-start">
            <div className="absolute top-0 pt-4 lg:pt-10 xl:pt-20 md:px-20 xl:px-10 xl:pb-20 md:w-96">
              <h3 className="text-bluegroup-300 text-center text-2xl md:text-3xl lg:text-5xl md:text-left font-semibold">Servicios</h3>
              <h2 className={`text-bluegroup-300 text-center text-${props.fontsize?props.fontsize-3:"4"}xl sm:text-${props.fontsize?props.fontsize-1:"4"}xl md:text-${props.fontsize?props.fontsize-3:"4"}xl  lg:text-${props.fontsize?props.fontsize-1:"6"}xl xl:text-${props.fontsize?props.fontsize:"7"}xl md:text-left font-bold`}>{service}</h2>
            </div>
          </div>
          <div className="absolute -bottom-14 mx-auto left-0 right-0 lg:left-24 xl:left-96 lg:mx-10 w-80 bg-bluegroup-200 py-8 px-8 shadow-lg mt-10 shadow-2xl">
                <img className="mx-auto my-5 mt-20 mb-14" src={require(`../../assests/OurServices/${type}_icon.png`)}/>
                <div className="text-3xl text-white font-semibold text-center">{service}</div>
                <div className="line bg-bluegroup-800 mb-16"></div>
          </div>
        </div>
        <div className="mt-32">
          <div className="max-w-7xl mx-auto px-10 text-bluegroup-100 text-center md:text-left text-xl md:text-2xl lg:text-3xl xl:text-4xl">{description}</div>
          <div className="content">
            {content}
          </div>
          <div className="max-w-7xl mx-auto px-10 md:pl-20 mt-12 flex flex-wrap justify-center md:justify-start">
            <div className="text-center">
              <button className="focus:outline-none mx-auto md:mx-0 block px-8 py-3 font-medium rounded-2xl text-white bg-bluegroup-800 hover:bg-bluegroup-900 md:text-2xl mb-10"
              onClick={()=>go("contact")}>Solicitar servicio</button>
              <button className="focus:outline-none mx-auto md:mx-0 block px-8 py-3 font-medium rounded-2xl text-white bg-bluegroup-100 hover:bg-bluegroup-300 md:text-2xl mb-20"
              onClick={goBack}><img className="inline mr-2" src={require("../../assests/OurServices/goBack_icon.png")}/>Volver</button>
            </div>
          </div>
        </div>
        <footer className="flex flex-wrap justify-between px-5 bg-bluegroup-100">
            <div className="text-white my-5 text-xs lg:text-md xl:text-lg pt-2 order-last md:order-none w-full text-center md:w-auto">©  2021 Todos los derechos reservados</div>
            <div className="text-white my-5 order-first md:order-none w-full text-center md:w-auto">
              <a href={links.linkedIn} target="_blank" rel="noopener noreferrer">
                <img className="inline mx-5 w-5" src={require('../../assests/Contact/linkedIn_icon.png')}/>
              </a>
              <a href={links.facebook} target="_blank" rel="noopener noreferrer">
                <img className="inline mx-5 w-3" src={require('../../assests/Contact/facebook_icon.png')}/>
              </a>
            </div>
            <div className="flex justify-center text-white text-center text-lg lg:text-xl xl:text-2xl my-5 w-full md:justify-end md:w-auto">
              <button className="bg-bluegroup-700 font-bold py-2 px-2 md:px-4 rounded-full inline mx-1 lg:mx-3 focus:outline-none focus:font-bold hover:bg-bluegroup-800" 
              onClick={()=>go("aboutUs")}>Nosotros</button>
              <button className="bg-bluegroup-700 font-bold py-2 px-2 md:px-4 rounded-full inline mx-1 lg:mx-3 focus:outline-none focus:font-bold hover:bg-bluegroup-800"
              onClick={()=>go("ourServices")}>Servicios</button>
              <button className="bg-bluegroup-700 font-bold py-2 px-2 md:px-4 rounded-full inline mx-1 lg:mx-3 focus:outline-none focus:font-bold hover:bg-bluegroup-800"
              onClick={()=>go("contact")}>Contacto</button>
            </div>
          </footer>
      </div>
  );
})
