import React from 'react';
import ServiceComponent from './ServiceComponent';
import {Helmet} from 'react-helmet'
export default function BrandingComponent() {

    const description = `Creamos marcas con ADN, entendemos que la identidad de la marca es lo más importante. 
    Gestionamos la marca para crear una conexión positiva con el Target.`

  return (
    <>
      <Helmet>
        <title>Branding | Blue Group Marketing | Bolivia</title>
        <meta
          name="description"
          content="Creamos marcas con ADN, entendemos que la identidad de la marca es lo más importante. Gestionamos la marca para crear una conexión positiva con el Target."
        />
        <meta name="keywords" content="branding, marca, naming, identidad" />
        <meta name="robots" content="index, follow"/>
        <meta name="googlebot" content="index, follow"/>
        <meta property="og:url" content={`${process.env.FRONTENDURL}BrandingServices`} />
        <link rel="canonical" href={process.env.FRONTENDURL} />
        <meta property="og:title" content="Branding | Blue Group Marketing | Bolivia" />
        <meta property="og:description" content="Creamos marcas con ADN, entendemos que la identidad de la marca es lo más importante. Gestionamos la marca para crear una conexión positiva con el Target." />
      </Helmet>
    <ServiceComponent description={description} service="BRANDING" type="Branding">
      <div className="max-w-7xl mx-auto">
        <ul className="text-3xl italic font-bold mt-20 text-center sm:text-left">
          <li className="text-bluegroup-300 mb-10"><img className="sm:inline w-20 mx-auto sm:mx-5" src={require('../../assests/OurServices/Branding/naming_icon.png')}/>Naming/ Creación de nombre</li>
          <li className="text-bluegroup-800 mb-10"><img className="sm:inline w-20 mx-auto sm:mx-5" src={require('../../assests/OurServices/Branding/concepto_icon.png')}/>Concepto de marca</li>
          <li className="text-bluegroup-100 mb-10"><img className="sm:inline w-20 mx-auto sm:mx-5" src={require('../../assests/OurServices/Branding/identidad_icon.png')}/>Identidad de marca</li>
          <li className="text-bluegroup-200 mb-10"><img className="sm:inline w-20 mx-auto sm:mx-5" src={require('../../assests/OurServices/Branding/manejo_icon.png')}/>Manejo de marca</li>
        </ul>
      </div>
    </ServiceComponent>
    </>
  );
}