import React from 'react';
import ServiceComponent from './ServiceComponent';
import {Helmet} from 'react-helmet'
export default function MarketingComponent() {

  const description = <>
                        <p className="mb-14">Diseñamos estrategias que integren los canales digitales y cumplan con los objetivos de negocio.</p>
                        <p>Creamos estrategias de contenido para cada canal digital.</p>
                      </>

  return (
    <>
    <Helmet>
      <title>Marketing Digital | Blue Group Marketing | Bolivia</title>
      <meta
        name="description"
        content="Diseñamos estrategias que integren los canales digitales y cumplan con los objetivos de negocio."
      />
      <meta name="keywords" content="Administración de Redes Sociales, publicitaria digital, marketing digital" />
      <meta name="robots" content="index, follow"/>
      <meta name="googlebot" content="index, follow"/>
      <meta property="og:url" content={`${process.env.FRONTENDURL}MarketingServices`} />
      <link rel="canonical" href={process.env.FRONTENDURL} />
      <meta property="og:title" content="Marketing Digital | Blue Group Marketing | Bolivia" />
      <meta property="og:description" content="Diseñamos estrategias que integren los canales digitales y cumplan con los objetivos de negocio." />
    </Helmet>
    <ServiceComponent description={description} service="MARKETING DIGITAL" type="Marketing">
      <div className="mx-auto" style={{maxWidth:"1400px"}}>
      <ul className="text-lg lg:text-2xl xl:text-3xl mt-20 text-center md:text-left">
        <li className="mb-20 flex justify-center flex-wrap md:flex-nowrap md:justify-start">
          <div>
            <img className="w-20 mx-auto sm:mx-5" src={require('../../assests/OurServices/Marketing/admin_icon.png')}/>
          </div>
          <div className="individual-content">
            <h2 className="text-bluegroup-800 italic font-bold">Administración de Redes Sociales</h2>
            <p className="text-bluegroup-100">Diseñamos contenido audiovisual totalmente atractivo y dirigido a la audiencia meta.</p>
          </div>
        </li>
        <li className="mb-20 flex flex-wrap justify-center flex-wrap md:flex-nowrap md:justify-start">
          <div>
            <img className="sm:inline w-20 mx-auto sm:mx-5" src={require('../../assests/OurServices/Marketing/design_icon.png')}/>
          </div>
          <div className="individual-content">
            <h2 className="text-bluegroup-300 italic font-bold"> Diseño y desarrollo de página web</h2>
            <p className="text-bluegroup-100">Creamos Sitios Web personalizados, que reflejan la identidad de la marca. Trabajamos para que tu negocio sea visible, único y seguro.</p>
          </div>
        </li>
        <li className="mb-20 flex flex-wrap justify-center flex-wrap md:flex-nowrap md:justify-start">
          <div>
            <img className="sm:inline w-20 mx-auto sm:mx-5" src={require('../../assests/OurServices/Marketing/pauta_icon.png')}/>
          </div>
          <div className="individual-content">
            <h2 className="text-bluegroup-100 italic font-bold">Pauta publicitaria digital</h2>
            <p className="text-bluegroup-100">Optimizamos la inversión de pauta en canales digitales, monitoreamos la inversión para maximizar los resultados.</p>
          </div>
        </li>
      </ul>
      </div>
    </ServiceComponent>
    </>
  );
}