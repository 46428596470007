import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import '../../styles/HomeStyles.css'
import { goTo } from '../util/utils';
import {Helmet} from 'react-helmet'
const links = {
  linkedIn:'https://www.linkedin.com/company/blue-group-marketing',
  whatsapp:'https://wa.me/59179960605',
  facebook:'https://www.facebook.com/bluegroupbolivia/',
  instagram: 'https://www.instagram.com/p/CSZavEEAkkm/?utm_medium=copy_link'
}


export default withRouter(function HomeComponent(props) {

  useEffect(() => {
    let queryParams = props.location.search
    if(queryParams){
      let section = queryParams.split("=")[1];
      document.querySelector(`#${section}`).scrollIntoView();
    }
    return () => {
    };
  }, [])

  return (
  <>
    <Helmet>
        <title>Somos una empresa apasionada por el Marketing | Blue Group Marketing | Bolivia</title>
        <meta
          name="description"
          content="Somos una Agencia de Marketing Digital y desarrollamos estrategias de Publicidad con servicios de Branding, BTL, Marketing Digital, Trade Marketingy Producción"
        />
        <meta name="keywords" content="páginas web, marketing digital, branding, diseño, diseno, web, bolivia, cochabamba" />
        <meta name="robots" content="index, follow"/>
        <meta name="googlebot" content="index, follow"/>
        <meta property="og:url" content={process.env.FRONTENDURL} />
        <link rel="canonical" href={process.env.FRONTENDURL} />
        <meta property="og:title" content="Somos una empresa apacionada por el Marketing | Blue Group Marketing | Bolivia" />
        <meta property="og:description" content="Somos una Agencia de Marketing Digital y desarrollamos estrategias de Publicidad con servicios de Branding, BTL, Marketing Digital, Trade Marketingy Producción" />
    </Helmet>
    <div className="home relative overflow-hidden bg-bluegroup-300">
      <div className="absolute sm:-left-96 md:-left-50 lg:-left-40 2xl:left-0 hidden xl:block">
          <img src={require("../../assests/home/Mask Group 0-3.png")} alt=""/>
      </div>
      <img className="absolute m-0 -top-56 lg:left-60 xl:left-96 w-72 hidden lg:block" src={require("../../assests/home/figure_1.png")}/>
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:w-full lg:pb-28 xl:pb-32">
            <div className="flex justify-center lg:justify-end text-white text-md md:text-xl lg:text-2xl 2xl:text-4xl mt-10 lg:mt-20">
              <button className="bg-bluegroup-700 font-bold py-2 px-2 md:px-4 rounded-full inline mx-1 md:mx-3 xl:mx-6 focus:outline-none focus:font-bold hover:bg-bluegroup-800" onClick={()=>goTo("aboutUs")}>Nosotros</button>
              <button className="bg-bluegroup-700 font-bold py-2 px-2 md:px-4 rounded-full inline mx-1 md:mx-3 xl:mx-6 focus:outline-none focus:font-bold hover:bg-bluegroup-800" onClick={()=>goTo("ourServices")}>Servicios</button>
              <button className="bg-bluegroup-700 font-bold py-2 px-2 md:px-4 rounded-full inline mx-1 md:mx-3 xl:mx-6 focus:outline-none focus:font-bold hover:bg-bluegroup-800" onClick={()=>goTo("contact")}>Contacto</button>
            </div>
            <main className=" flex flex-col md:flex-row flex-wrap content-center justify-center lg:justify-end mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="w-80 h-72 md:h-96 lg:w-96 xl:w-96  m-auto">
                <div className="relative w-full h-full m-auto xl:transform xl:translate-x-36 2xl:translate-x-28">
                  <img className="spin-center-position absolute img-inside " src={require('../../assests/home/Group224.png')} />
                  <div className="absolute spin-center-position img-outside">
                    <img className="spin-img w-full h-full " src={require('../../assests/home/Group225.png')} />
                  </div>
                  
                </div>
              </div>
              <div className="text-center lg:text-right">
                <h1 className="home-title text-4xl tracking-tight mb-10 lg:mb-20 lg:mt-20 sm:text-5xl xl:text-6xl">
                  <div className="mb-2 text-white">Somos una empresa</div>
                  <div className="mb-2 text-white"><span className="font-semibold">apasionada</span> por el</div>
                  <div className="mb-2 text-white font-bold">MARKETING</div>
                </h1>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-end">
                  <div className="rounded-full shadow">
                    <button
                      onClick={()=>goTo("contact")}
                      className="focus:outline-none w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium italic rounded-full text-white bg-bluegroup-800 hover:bg-bluegroup-900 md:py-4 md:text-3xl md:px-5"
                    >
                      ¡Contáctanos!
                    </button>
                  </div>
                </div>
                <p className="flex items-center mt-3  justify-center mt-5 sm:mx-auto md:mt-5 lg:justify-end">
                    <a href={links.linkedIn} target="_blank" rel="noopener noreferrer"><img className="icon" title="LinkedIn" src={require('../../assests/home/linkedIn_icon.png')}/></a>
                    <a href={links.whatsapp} target="_blank" rel="noopener noreferrer"><img className="icon" title="Whatsapp" src={require('../../assests/home/whatsapp_icon.png')}/></a>
                    <a href={links.facebook} target="_blank" rel="noopener noreferrer"><img className="icon" title="Facebook" src={require('../../assests/home/facebook_icon.png')}/></a>
                    <a href={links.instagram} target="_blank" rel="noopener noreferrer"><img className="icon" title="Instagram" src={require('../../assests/home/instagram_icon.png')}/></a>
                </p>
              </div>
            </main>
          </div>
        </div>
  </div> 
  </>
);})

